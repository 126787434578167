import { createApp } from "vue";
import { createPinia } from "pinia";
import { Tooltip } from "bootstrap";
import App from "./App.vue";
import mitt from "mitt";

import router from "@/router";

//Element plus
import ElementPlus from "element-plus";
import de from "element-plus/dist/locale/de.mjs";
import dayjs from "dayjs";
dayjs.Ls.de ??= {} as ILocale;
dayjs.Ls.de.weekStart = 1;

import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initKtIcon } from "@/core/plugins/keenthemes";
import { LoadingPlugin } from "vue-loading-overlay";
import * as Sentry from "@sentry/vue";

import "@/core/plugins/prismjs";
//Toast
import Toast from "vue-toastification";

import initEchoConfig from "@/vd/helpers/pusherHelper";

//Permissions
import vdPermissions from "@/vd/helpers/permissions";
if (!window.vdPermissions) {
  window.vdPermissions = { jsonPermissions: 0 };
}

const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.use(createPinia());

//Permission
app.use(vdPermissions);

if (import.meta.env.PROD) {
  const tracePropagationTarget = import.meta.env.VITE_BASE_URL;
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_URL,
    environment: import.meta.env.MODE,
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [tracePropagationTarget, /^\/api\/v1\//],
  });
}

app.use(router);

app.use(ElementPlus, {
  locale: de,
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initKtIcon(app);
initVeeValidate();
initEchoConfig();

app.use(i18n);

//Toast
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true,
  timeout: 3000,
  hideProgressBar: true,
  icon: true,
  pauseOnHover: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
});

app.directive("tooltip", (el) => {
  new Tooltip(el);
});

app.use(LoadingPlugin);

app.config.performance = true;

app.mount("#app");
