import { defineStore, storeToRefs } from "pinia";
import ApiService from "@/core/services/ApiService";
import { useClientStore } from "./clients";
import { ref } from "vue";
import type { MenuItem } from "@/core/config/MainMenuConfig";
import type {
  Process,
  ProcessQuestionDependencies,
  AdditionalQuestionGroupBlock,
  AdditionalQuestionGroupContent,
  ProcessStatus,
  ProcessQuestionGroupNote,
  QuestionGroupAttachment,
} from "../data/process";
import { useToast } from "vue-toastification";
import type { Todo } from "../data/todos";
import axios, { AxiosError } from "axios";

const toast = useToast();

export const useProcessStore = defineStore("process", {
  state: () => ({
    processes: ref<Array<Process>>([] as Array<Process>),
    processMenuItemsLoaded: false,
    processMenuItems: ref<Array<MenuItem>>([]),
    process: ref<Process>({} as Process),
    alias: ref<string>(""),
    questionGroupInfoText: ref<string>(""),
    dependencies: ref<Array<ProcessQuestionDependencies>>([]),
    editAFQuestionGroupId: ref<string | number>(),
    editQuestionGroupNoteId: ref<string | number>(),
    editQuestionGroupTodoId: ref<string | number>(),
    editQuestionGroupAttachmentId: ref<string | number>(),
    processAdditionalFields: ref<{
      [key: string]: AdditionalQuestionGroupBlock[];
    }>({}),
    questionGroupAttachments: ref<{ [key: string]: QuestionGroupAttachment[] }>(
      {},
    ),
    questionGroupsLoaded: ref<Boolean>(false),
    questionGroupEditData: ref<AdditionalQuestionGroupContent>(
      {} as AdditionalQuestionGroupContent,
    ),
    processAdditionalSections: ref<Array<AdditionalQuestionGroupContent>>([]),
    processGroupStatus: ref<ProcessStatus[]>({} as ProcessStatus[]),
    processGroupNotes: ref<ProcessQuestionGroupNote[]>(
      {} as ProcessQuestionGroupNote[],
    ),
    processCopyAliasList: ref<string>(),
    processCopyLocationIdList: ref<string>(),
    processCopyOrganizationalUnitIdList: ref<string>(),
    processTodos: ref<Todo[]>({} as Todo[]),
    processUserdata: ref<string[]>([]),
    processUpdateTrigger: ref<Boolean>(false),
    savingSpinner: ref<Boolean>(false),
    externalUpdated: ref<Boolean>(false),
    showChangeHistoryButton: ref<Boolean>(false),
    hiddenQuestionGroups: ref<Array<Number>>([]),
    copyableGroupId: ref<Number>(),
    processPreview: ref<string>(),
  }),
  getters: {
    getProcesses(state) {
      return state.processes;
    },
    getProcess(state) {
      return state.process;
    },
    getProcessMenuItems(state) {
      return state.processMenuItems;
    },
    getAlias(state) {
      return state.alias;
    },
    getQuestionGroupInfoText(state) {
      return state.questionGroupInfoText;
    },
    getProcessDependencies(state) {
      return state.dependencies;
    },
    getEditAFQuestionGroupId(state) {
      return state.editAFQuestionGroupId;
    },
    getEditQuestionGroupNoteId(state) {
      return state.editQuestionGroupNoteId;
    },
    getEditQuestionGroupTodoId(state) {
      return state.editQuestionGroupTodoId;
    },
    getEditQuestionGroupAttachmentId(state) {
      return state.editQuestionGroupAttachmentId;
    },
    getProcessAdditionalFields(state) {
      return state.processAdditionalFields;
    },
    getQuestionGroupsLoaded(state) {
      return state.questionGroupsLoaded;
    },
    getQuestionGroupEditData(state) {
      return state.questionGroupEditData;
    },
    getQuestionGroupAttachments(state) {
      return state.questionGroupAttachments;
    },
    getProcessAdditionalSections(state) {
      return state.processAdditionalSections;
    },
    getProcessUserdata(state) {
      return state.processUserdata;
    },
    getProcessUpdateTrigger(state) {
      return state.processUpdateTrigger;
    },
    getSavingSpinner(state) {
      return state.savingSpinner;
    },
    getHiddenQuestionGroups(state) {
      return state.hiddenQuestionGroups;
    },
    getProcessGroupStatus(state) {
      return state.processGroupStatus;
    },
    getProcessGroupNotes(state) {
      return state.processGroupNotes;
    },
    getProcessTodos(state) {
      return state.processTodos;
    },
    getProcessCopyAliasList(state) {
      return state.processCopyAliasList;
    },
    getProcessCopyLocationIdList(state) {
      return state.processCopyLocationIdList;
    },
    getProcessCopyOrganizationalUnitIdList(state) {
      return state.processCopyOrganizationalUnitIdList;
    },
    getProcessPreview(state) {
      return state.processPreview;
    },
  },

  actions: {
    async queryProcessMenuItems() {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);
      return await ApiService.get(
        `/api/v1/${getClientURL.value}show-process-menu`,
      ).then((response) => {
        this.processMenuItems = response.data;
        this.processMenuItemsLoaded = true;
        return this.processMenuItems;
      });
    },

    async queryProcesses(params: object = {}) {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);
      return await ApiService.query(
        `/api/v1/${getClientURL.value}processes`,
        params,
      ).then((response) => {
        this.process = {} as Process;
        this.processes = response.data;
        return this.processes;
      });
    },

    async queryProcess(
      slug: string = "",
      cashRegisterSystemId: string | undefined = undefined,
      params: object = {},
    ) {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);

      const url = cashRegisterSystemId
        ? slug + "?crs_id=" + cashRegisterSystemId
        : slug;

      return await ApiService.query(
        `/api/v1/${getClientURL.value}processes/${url}`,
        params,
      ).then((response) => {
        this.process = response.data;
        this.processes = [];

        if (this.process.children && this.process.children.length > 0) {
          this.processes = this.process.children;
        }

        return this.process;
      });
    },

    async queryProcessQuestionGroupQuestions(id: number) {
      return await ApiService.get(
        `/api/v1/process-question-groups/questions?filter[group_id]=${id}`,
      ).then((response) => {
        return response.data;
      });
    },

    async updateProcessStatus(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);
      const urlParams = new URLSearchParams();

      if (locationId) {
        urlParams.append("location_id", locationId);
      }

      if (organizationalUnitId) {
        urlParams.append("organizational_unit_id", organizationalUnitId);
      }

      const url = `/api/v1/${getClientURL.value}process-status/${processAlias}${
        urlParams.toString() ? "?" + urlParams.toString() : ""
      }`;

      const response = await ApiService.post(url, params, false);

      if (response) {
        this.processes = this.processes.map((process) => {
          if (process.alias === processAlias) {
            if (!locationId && !organizationalUnitId) {
              process.process_status = response.data;

              if (this.process) {
                this.process.process_status = response.data;
              }
            }

            if (locationId) {
              const updatedLocations =
                process.locations?.map((location) => {
                  if (location.location.id === locationId) {
                    return {
                      ...location,
                      process_status: response.data,
                    };
                  }
                  return location;
                }) || process.locations;

              process.locations = updatedLocations;
            }

            if (organizationalUnitId) {
              const updatedOrganizationalUnits =
                process.organizational_units?.map((unit) => {
                  if (unit.organizational_unit.id === organizationalUnitId) {
                    return {
                      ...unit,
                      process_status: response.data,
                    };
                  }
                  return unit;
                }) || process.organizational_units;

              process.organizational_units = updatedOrganizationalUnits;
            }

            return process;
          }
          return process;
        });
      }
    },

    async getProcessUserData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
    ) {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);

      this.processUserdata = [];
      this.processAdditionalSections = [];
      this.processAdditionalFields = {};
      this.hiddenQuestionGroups = [];
      this.processGroupStatus = [];
      this.processTodos = [];
      this.processGroupNotes = [];

      const urlParams = new URLSearchParams();

      if (locationId) {
        urlParams.append("location_id", locationId);
      }

      if (organizationalUnitId) {
        urlParams.append("organizational_unit_id", organizationalUnitId);
      }

      const url = `/api/v1/${
        getClientURL.value
      }process-user-data/${processAlias}${
        urlParams.toString() ? "?" + urlParams.toString() : ""
      }`;

      const response = await ApiService.get(url);

      if (response.data.user_data !== undefined) {
        this.processUserdata = response.data.user_data;
      }

      if (response.data.additional_fields !== undefined) {
        this.processAdditionalFields = response.data.additional_fields;
      }

      if (response.data.attachments !== undefined) {
        this.questionGroupAttachments = response.data.attachments;
      }

      if (response.data.additional_sections !== undefined) {
        this.processAdditionalSections = response.data.additional_sections;
      }

      if (response.data.ignored_groups !== undefined) {
        this.hiddenQuestionGroups = response.data.ignored_groups;
      }

      if (response.data.group_status !== undefined) {
        this.processGroupStatus = response.data.group_status;
      }

      if (response.data.group_notes !== undefined) {
        this.processGroupNotes = response.data.group_notes;
      }

      if (response.data.todos !== undefined) {
        this.processTodos = response.data.todos;
      }

      return this.processUserdata;
    },

    async updateProcessUserData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);

      const urlParams = new URLSearchParams();

      if (locationId) {
        urlParams.append("location_id", locationId);
      }

      if (organizationalUnitId) {
        urlParams.append("organizational_unit_id", organizationalUnitId);
      }

      const url = `/api/v1/${
        getClientURL.value
      }process-user-data/${processAlias}${
        urlParams.toString() ? "?" + urlParams.toString() : ""
      }`;

      const cleanEmpty = (obj) => {
        if (Array.isArray(obj)) {
          return obj
            .map((v) => (v && typeof v === "object" ? cleanEmpty(v) : v))
            .filter((v) => !(v == null) && !(v.length == 0))
            .map((v) => (Array.isArray(v) ? cleanEmpty(v) : v));
        } else if (typeof obj === "object" && obj !== null) {
          return Object.fromEntries(
            Object.entries(obj)
              .map(([k, v]) => [k, cleanEmpty(v)])
              .filter(
                ([, v]) =>
                  !(
                    v == null ||
                    (typeof v === "object" && Object.keys(v).length === 0)
                  ),
              ),
          );
        } else {
          return obj;
        }
      };

      if (this.processAdditionalSections) {
        if (params["additional_sections"]) {
          params["additional_sections"] = this.processAdditionalSections;
        } else {
          Object.assign(params, {
            additional_sections: this.processAdditionalSections,
          });
        }
      } else {
        params["additional_sections"] = [];
      }

      if (this.processAdditionalFields) {
        if (params["additional_fields"]) {
          params["additional_fields"] = this.processAdditionalFields;
        } else {
          Object.assign(params, {
            additional_fields: this.processAdditionalFields,
          });
        }
      } else {
        params["additional_fields"] = {};
      }

      return await ApiService.post(url, cleanEmpty(params), false).then(
        (response) => {
          this.processUserdata = response.data.user_data;

          if (response.data.additional_fields !== undefined) {
            this.processAdditionalFields = response.data.additional_fields;
          } else {
            this.processAdditionalFields = {};
          }

          if (response.data.attachments !== undefined) {
            this.questionGroupAttachments = response.data.attachments;
          } else {
            this.questionGroupAttachments = {};
          }

          if (response.data.additional_sections !== undefined) {
            this.processAdditionalSections = response.data.additional_sections;
          } else {
            this.processAdditionalSections = [];
          }

          if (response.data.ignored_groups !== undefined) {
            this.hiddenQuestionGroups = response.data.ignored_groups;
          } else {
            this.hiddenQuestionGroups = [];
          }

          if (response.data.group_status !== undefined) {
            this.processGroupStatus = response.data.group_status;
          } else {
            this.processGroupStatus = [];
          }

          if (response.data.group_notes !== undefined) {
            this.processGroupNotes = response.data.group_notes;
          } else {
            this.processGroupNotes = {} as ProcessQuestionGroupNote[];
          }

          if (response.data.todos !== undefined) {
            this.processTodos = response.data.todos;
          } else {
            this.processTodos = {} as Todo[];
          }

          return this.processUserdata;
        },
      );
    },

    async updateQuestionGroupVisibility(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      groupId: number | null = null,
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }

        if (groupId) {
          this.hiddenQuestionGroups.push(groupId);
        }

        const params = {
          ignored_groups: this.getHiddenQuestionGroups,
        };

        const url = `/api/v1/${
          getClientURL.value
        }process-user-data-group-visibility/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;

        const response = await ApiService.post(url, params, false);
        this.hiddenQuestionGroups = response.data.ignored_groups;
        this.getProcessUserData(processAlias, locationId, organizationalUnitId);
      } catch (error) {
        toast.error("Aktualisieren fehlgeschlagen");
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async updateProcessGroupStatus(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }
        const url = `/api/v1/${
          getClientURL.value
        }process-user-data-group-status/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;

        const response = await ApiService.post(url, params, false);
        this.processGroupStatus = response.data;
      } catch (error) {
        toast.error("Aktualisieren fehlgeschlagen!");
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async getCopyableGroupList(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      const clientStore = useClientStore();
      const { getClientURL } = storeToRefs(clientStore);
      const urlParams = new URLSearchParams();

      if (locationId) {
        urlParams.append("location_id", locationId);
      }

      if (organizationalUnitId) {
        urlParams.append("organizational_unit_id", organizationalUnitId);
      }
      const url = `/api/v1/${
        getClientURL.value
      }process-user-data-group-copyable-list/${processAlias}${
        urlParams.toString() ? "?" + urlParams.toString() : ""
      }`;

      const response = await ApiService.post(url, params, false);
      return response.data;
    },

    async copyGroupData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }
        const url = `/api/v1/${
          getClientURL.value
        }process-user-data-copy-group/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;

        const response = await ApiService.post(url, params, false);

        this.processUserdata = response.data.user_data;

        if (response.data.additional_fields !== undefined) {
          this.processAdditionalFields = response.data.additional_fields;
        } else {
          this.processAdditionalFields = {};
        }
        this.externalUpdated = true;
        return this.processUserdata;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async copyProcessData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }

        const url = `/api/v1/${getClientURL.value}process-copy/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;
        const response = await ApiService.post(url, params, false);
        await this.queryProcess(processAlias);

        toast.success("Prozess kopiert");

        return response.data.url;
      } catch (error) {
        toast.error("Prozess-Kopieren fehlgeschlagen!");
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async getProcessPreviewData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }

        const url = `/api/v1/${
          getClientURL.value
        }process-get-preview/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;

        const response = await ApiService.get(url);

        if (response.data.html !== undefined) {
          this.processPreview = response.data.html;
        }
      } catch (error) {
        toast.error("Laden der Vorschau fehlgeschlagen!");
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async getProcessGroupNoteContent(
      processAlias: string,
      groupId: string,
      locationId: string = "",
      organizationalUnitId: string = "",
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }
        urlParams.append("group_id", groupId);

        const url = `/api/v1/${
          getClientURL.value
        }process-group-notes/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;

        const response = await ApiService.get(url);
        return response.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error?.response?.data.message !== "Group note not found") {
            toast.error("Laden fehlgeschlagen!");
            if (error instanceof Error) {
              console.error(error.message);
            }
            throw error;
          }
        }
      }
    },

    async updateProcessGroupNoteContent(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
      deleted: Boolean = false,
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }
        const url = `/api/v1/${
          getClientURL.value
        }process-group-notes/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;

        const response = await ApiService.post(url, params, false);
        this.processGroupNotes = response.data;
        if (deleted) {
          toast.success("Notiz gelöscht");
        } else {
          toast.success("Notiz gespeichert");
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        toast.error("Aktualisierung fehlgeschlagen!");
        throw error;
      }
    },

    async addHistoryComment(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      params: object = {},
    ) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }

        const url = `/api/v1/${
          getClientURL.value
        }process-user-data-history-comment/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;
        await ApiService.post(url, params, false);
        toast.success("Kommentar gespeichert");
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        toast.error("Aktualisierung fehlgeschlagen!");
        throw error;
      }
    },

    async resetProcessData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      list: boolean = false,
    ): Promise<string> {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }

        const url = `/api/v1/${
          getClientURL.value
        }process-reset/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;
        const response = await ApiService.delete(url);

        if (list) {
          //this.queryProcesses();
          const currentProcessIndex = this.processes?.findIndex(
            (x) => x.alias === processAlias,
          );
          if (currentProcessIndex !== -1 && currentProcessIndex !== undefined) {
            const currentProcess = this.processes[currentProcessIndex];

            let targetIndex: number | undefined;

            if (locationId && !organizationalUnitId) {
              targetIndex = currentProcess.locations?.findIndex(
                (x) => x.location.id === locationId,
              );
              if (targetIndex !== -1 && targetIndex !== undefined) {
                this.processes[currentProcessIndex].locations?.splice(
                  targetIndex,
                  1,
                );
              }
            } else if (organizationalUnitId && !locationId) {
              targetIndex = currentProcess.organizational_units?.findIndex(
                (x) => x.organizational_unit.id === organizationalUnitId,
              );
              if (targetIndex !== -1 && targetIndex !== undefined) {
                this.processes[
                  currentProcessIndex
                ].organizational_units?.splice(targetIndex, 1);
              }
            } else if (locationId && organizationalUnitId) {
              targetIndex =
                currentProcess.location_organizational_units?.findIndex(
                  (x) =>
                    x.organizational_unit.id === organizationalUnitId &&
                    x.location.id === locationId,
                );
              if (targetIndex !== -1 && targetIndex !== undefined) {
                this.processes[
                  currentProcessIndex
                ].location_organizational_units?.splice(targetIndex, 1);
              }
            }
            toast.success("Prozessdaten zurückgesetzt!");
          }
        }
        return response.data.url;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        toast.error("Prozess-Zurücksetzen fehlgeschlagen!");
        throw error;
      }
    },

    async deleteAdditionalProcessData(
      processAlias: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      list: boolean = false,
    ): Promise<string> {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);
        const urlParams = new URLSearchParams();

        if (locationId) {
          urlParams.append("location_id", locationId);
        }

        if (organizationalUnitId) {
          urlParams.append("organizational_unit_id", organizationalUnitId);
        }

        const url = `/api/v1/${
          getClientURL.value
        }process-delete/${processAlias}${
          urlParams.toString() ? "?" + urlParams.toString() : ""
        }`;
        const response = await ApiService.delete(url);

        if (list) {
          const currentProcessIndex = this.processes?.findIndex(
            (x) => x.alias === processAlias,
          );
          if (currentProcessIndex !== -1 && currentProcessIndex !== undefined) {
            const currentProcess = this.processes[currentProcessIndex];

            let targetIndex: number | undefined;

            if (locationId && !organizationalUnitId) {
              targetIndex = currentProcess.locations?.findIndex(
                (x) => x.location.id === locationId,
              );
              if (targetIndex !== -1 && targetIndex !== undefined) {
                this.processes[currentProcessIndex].locations?.splice(
                  targetIndex,
                  1,
                );
              }
            } else if (organizationalUnitId && !locationId) {
              targetIndex = currentProcess.organizational_units?.findIndex(
                (x) => x.organizational_unit.id === organizationalUnitId,
              );
              if (targetIndex !== -1 && targetIndex !== undefined) {
                this.processes[
                  currentProcessIndex
                ].organizational_units?.splice(targetIndex, 1);
              }
            } else if (locationId && organizationalUnitId) {
              targetIndex =
                currentProcess.location_organizational_units?.findIndex(
                  (x) =>
                    x.organizational_unit.id === organizationalUnitId &&
                    x.location.id === locationId,
                );
              if (targetIndex !== -1 && targetIndex !== undefined) {
                this.processes[
                  currentProcessIndex
                ].location_organizational_units?.splice(targetIndex, 1);
              }
            }
            toast.success("Prozessdaten gelöscht!");
          }
        }

        return response.data.url;
      } catch (error) {
        toast.success("Prozessdaten konnten nicht gelöscht werden!");
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    setQuestionGroupAttachments(groupId, newAttachments) {
      if (!this.questionGroupAttachments[groupId]) {
        this.questionGroupAttachments[groupId] = [];
      }
      this.questionGroupAttachments = {
        ...this.questionGroupAttachments,
        [groupId]: newAttachments,
      };
    },

    async storeProcessGroupAttachments(
      slug: string,
      data: Object,
      groupId: string,
      fileId: string,
      locationId: string = "",
      organizationalUnitId: string = "",
      onUploadProgress?: (fileId: string, progress: number) => void,
    ) {
      try {
        const groupIdValue = "qg" + groupId;
        const resource = `/api/v1/process-user-data-attachment/${slug}`;

        await ApiService.csrf();

        const response = await axios.post(resource, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent && progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              if (onUploadProgress) {
                onUploadProgress(fileId, progress);
              }
            }
          },
        });

        toast.success("Anlagen erfolgreich hinterlegt");
        this.setQuestionGroupAttachments(groupIdValue, response.data);

        if (response.status !== 200 && response.status !== 201) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          const errorMessage =
            error.response?.data?.message ??
            "Es ist ein Fehler aufgetreten. Anlagen konnten nicht gespeichert werden.";
          toast.error(errorMessage);
        }
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    },

    async deleteProcessGroupAttachment(uuid: string) {
      try {
        const clientStore = useClientStore();
        const { getClientURL } = storeToRefs(clientStore);

        await ApiService.delete(
          `/api/v1/${getClientURL.value}process-user-data-group-attachment-delete/${uuid}`,
        )
          .then((response) => {
            this.questionGroupAttachments = response.data;
          })
          .catch((error) => {
            const errorMessage = error.response.data.message;
            if (errorMessage) {
              toast.error(errorMessage);
            } else {
              toast.error(
                "Es ist ein Fehler aufgetreten. Anlagen konnten nicht gelöscht werden.",
              );
            }
          });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },
  },
});
