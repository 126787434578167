function paginationUrl(
  url: string,
  page: number = 1,
  perPage: number = 10,
): string {
  const pagination = `per_page=${perPage}&page=${page}`;
  const separator = url.includes("?") ? "&" : "?";
  return `${url}${separator}${pagination}`;
}

export { paginationUrl };
