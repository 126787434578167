import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import type { Company } from "@/vd/data/company";
import { ref } from "vue";
import type { MetaData } from "../data/metaData";
import { paginationUrl } from "../helpers/paginationHelper";

export const useClientStore = defineStore("clients", {
  state: () => ({
    clientListItems: ref<Array<Company>>([] as Array<Company>),
    clientListItemMeta: ref<MetaData>({} as MetaData),
    clients: ref<Array<Company>>([] as Array<Company>),
    client: ref<Company>({} as Company),
    clientMeta: ref<MetaData>({} as MetaData),
    clientUrl: ref<string>(""),
  }),
  getters: {
    getClientListItems(state) {
      return state.clientListItems;
    },
    getClientListItemMeta(state) {
      return state.clientListItemMeta;
    },
    getClients(state) {
      return state.clients;
    },
    getClient(state) {
      return state.client;
    },
    getClientMeta(state) {
      return state.clientMeta;
    },
    getClientURL(state) {
      return state.client.id !== undefined ? `clients/${state.client.id}/` : "";
    },
    isClient(state) {
      return state.client.id !== undefined ? true : false;
    },
  },
  actions: {
    async queryClients(page: number = 1, perPage: number = 10) {
      try {
        const filterFunctionsModule = await import(
          "@/vd/helpers/filterFunctions"
        );
        const { getFilter } = filterFunctionsModule;
        const query = getFilter("client");
        let url = `/api/v1/clients${query}`;

        url = paginationUrl(url, page, perPage);

        await ApiService.get(url).then((response) => {
          this.clients = response.data.data;
          this.clientMeta = response.data.meta;
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },
    async getCompanyClientListItems(
      page: number = 1,
      perPage: number = 20,
      filters = "",
    ) {
      try {
        let query = "";

        if (filters) {
          query = "?filter[Firma]=" + filters;
        }

        let url = `/api/v1/client-list${query}`;
        url = paginationUrl(url, page, perPage);

        return await ApiService.get(url).then((response) => {
          if (filters && page === 1) {
            this.clientListItems = response.data.data;
          } else {
            this.clientListItems.push(...response.data.data);
          }
          this.clientListItemMeta = response.data.meta;

          return this.clientListItems;
        });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },
    async getSingleClient(id: string) {
      try {
        await ApiService.get(`/api/v1/clients`, id).then(
          (response) => (this.client = response.data),
        );
      } catch (error) {
        console.error("Get single client error", error);
        throw error;
      }
    },
    async updateClient(id: string, params: object) {
      try {
        await ApiService.update(`/api/v1/clients`, params, id, true);
        return true;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async unloadClient() {
      this.client = {} as Company;
    },
  },
});
