import ApiService from "@/core/services/ApiService";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

const initEchoConfig = () => {
  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: "reverb",
    key: import.meta.env.VITE_REVERB_APP_KEY,
    // authEndpoint: import.meta.env.VITE_APP_API_URL+'/broadcasting/auth',
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authorizer: (channel) => {
      return {
        authorize: (socketId, callback) => {
          ApiService.post(
            import.meta.env.VITE_APP_API_URL + "/broadcasting/auth",
            {
              socket_id: socketId,
              channel_name: channel.name,
            },
          )
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              console.log("error: ", +error.data);
              callback(false, error);
            });
        },
      };
    },
  });
};

export default initEchoConfig;
