import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { useAuthStore } from "../../stores/auth";

export const useSettingsStore = defineStore("settings", {
  actions: {
    async updateSettings(id: string, params: object) {
      try {
        const response = await ApiService.update(
          `/api/v1/settings`,
          params,
          id,
          true,
        );
        const authStore = useAuthStore();
        authStore.settings = response.data;

        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },

    async querySettings() {
      try {
        const storedData = sessionStorage.getItem("settings");
        const authStore = useAuthStore();

        if (storedData) {
          authStore.settings = JSON.parse(storedData);
          return storedData;
        }

        const response = await ApiService.get(`/api/v1/settings`);

        sessionStorage.setItem("settings", JSON.stringify(response.data));

        authStore.settings = response.data;

        return response.data;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        throw error;
      }
    },
  },
});
